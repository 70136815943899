import { Link } from "gatsby"
import React from "react"
import Image from "../components/image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Video from "../components/video"
import resume from "../downloads/TonyWeissingerResume2021.pdf"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <b>Audition Sample</b>
    <Video
      videoSrcURL="https://www.youtube.com/embed/f2lJsNZfPOA"
      videoTitle="Antonio Weissinger Audition Sample"
    />
    <b>All American on site</b>
    <Video
      videoSrcURL="https://www.youtube.com/embed/O6B9u4XZvok"
      videoTitle="All American on site"
    />
    <b>Folk</b>
    <Video
      videoSrcURL="https://www.youtube.com/embed/_tO480trqBU"
      videoTitle="Folk"
    />
    <b>Jump!</b>
    <Video
      videoSrcURL="https://www.youtube.com/embed/lgArb7FjmL0"
      videoTitle="Jump"
    />
    <hr />
    <h3>Younger Tony</h3>
    <b>Modern Major General</b> from <i>Pirates of Penzance</i>
    <Video
      videoSrcURL="https://www.youtube.com/embed/f8lstgr_g8I"
      videoTitle="Modern Major General"
    />
    <b>When Your Feet Don't Touch the Ground</b> from <i>Finding Neverland</i>{" "}
    (with Teddy Edgar)
    <Video
      videoSrcURL="https://www.youtube.com/embed/Ky90lhRXqxY"
      videoTitle="When Your Feet Don't Touch the Ground"
    />
    <b>Chef Antonio's Cooking Show</b>
    <Video
      videoSrcURL="https://www.youtube.com/embed/u8UsLCfoWL0"
      videoTitle="Chef Antonio's Cooking Show"
    />
    <h3>
      <Link to="/gallery/">Gallery</Link>
    </h3>
    <h3>
      <a
        href={resume}
        download="TonyWeissingerResume2021.pdf"
        without
        rel="noopener noreferrer"
        target="_blank"
      >
        Download My Resume
      </a>
      <br />
    </h3>
  </Layout>
)

export default IndexPage
